.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    & > img {
      width: 70px;
      height: 70px;
      animation: logo-rotation 1s ease infinite;
    }
  }
  
  @keyframes logo-rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  