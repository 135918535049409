.login-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5fcf3;
  .login-section {
    display: flex;
    justify-content: center;
    margin: 0px 20px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%) !important;
    .login-aside {
      width: 220px;
      padding: 0 20px 70px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 70px;
      background-image: url("../../assets/images/background.svg"),
        linear-gradient(to top, #34d399ad 0%, #2ba7dfb0 100%);
      @media (max-width: 1050px) {
        display: none;
      }
      .register-now {
        text-align: center;
        margin-bottom: 10px;
        color: white;
      }
      .register-link {
        color: #fff;
        border: 1px solid #fff;
        text-decoration: none;
        padding: 6px 12px;
        border-color: #fff;
        font-size: 15px;
        border-radius: 5px;
        align-items: center;
      }
    }
    .login-form {
      display: flex;
      background-color: white;
      padding: 50px 0;
      align-items: center;
      justify-content: center;
      min-width: 600px;
      @media (max-width: 680px) {
        min-width: 500px;
        padding: 30px 0;
      }
      @media (max-width: 550px) {
        min-width: 400px;
      }
      @media (max-width: 430px) {
        min-width: 330px;
        padding: 15px 0;
      }
      @media (max-width: 360px) {
        min-width: 300px;
        padding: 15px 15px;
      }
      @media (max-width: 330px) {
        min-width: 280px;
      }
      .login-form-section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .login-head {
          color: #2ba7df;
          margin-bottom: 4px;
          font-size: 30px;
          font-weight: 700;
          @media (max-width: 430px) {
            font-size: 24px;
          }
          @media (max-width: 430px) {
            font-size: 24px;
          }
        }
        .inscription-form {
          display: flex;
          justify-content: center;
          flex-direction: column;
          button {
            background-color: #2ba7df;
            color: white;
            border: 2px solid transparent;
            font-weight: 700;
            font-size: 18px;
            padding: 10px 20px;
            border-radius: 10px;
            margin-top: 20px;
            cursor: pointer;
            &:hover {
              background: white;
              color: #2ba7df;
              border-color: #2ba7df;
            }
          }
        }

        .email-input,
        .password-input {
          display: flex;
          margin-top: 10px;
          flex-direction: column;
          justify-content: start;
          gap: 4px;
          input {
            width: 280px;
            height: 35px;
            padding-left: 10px;
            border: 1px solid rgb(180, 180, 180);
            border-radius: 5px;
          }
          label {
            font-weight: 500;
            font-size: 16px;
            color: #2ba7df;
          }
        }
        @media (max-width: 550px) {
          .email-input input,
          .password-input input {
            height: 25px;
          }
          .login-link button {
            font-size: 15px;
            padding: 5px 10px;
          }
        }
      }
    }
  }
}
a.forget-pass-route {
  color: #6c757d;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 7px;
  @media (max-width: 550px) {
    font-size: 14px;
  }
}

.errorParagraph {
  color: rgb(255, 59, 59);
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
  text-align: center;
}
