.reset-wrapper {
  min-height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #e5fcf3;
}
.reset-section {
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 20%) !important;
}
.reset-aside {
  width: 220px;
  padding: 0 20px 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 70px;
  background-image: url("../../assets/images/background.svg"),
    linear-gradient(to top, #34d399ad 0%, #2ba7dfb0 100%);
  @media (max-width: 1050px) {
    display: none;
  }

  p {
    color: #fff;
    text-align: center;
  }
}

.reset-form {
  min-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 389px;
  background-color: #fff;
  @media (max-width: 700px) {
    min-width: 600px;
    height: 320px;
  }
  @media (max-width: 600px) {
    min-width: 500px;
    height: 320px;
  }
  @media (max-width: 520px) {
    min-width: 420px;
    height: 280px;
  }
  @media (max-width: 450px) {
    min-width: 400px;
    height: 280px;
  }
  @media (max-width: 415px) {
    min-width: 340px;
    height: 280px;
    margin: 10px;
  }
  @media (max-width: 340px) {
    min-width: 300px;
    height: 280px;
  }
  p.fp-error{
    color: red;
    max-width: 280px;
    font-size: 11px;
    margin-top: 4px;
  }
  p.accpted-passes{
    color: #57b5c9;
    font-size: 14px;
    text-align: center;
    margin-top: 7px;
  }
  .reset-text {
    color: #2ba7df;
    margin-bottom: 4px;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    color: #6c757d;
  }
  input {
    width: 280px;
    height: 35px;
    padding-left: 10px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 5px;
    margin-top: 15px;
  }
  .sendPassword {
    background-color: #2ba7df;
    color: white;
    border: none;
    font-weight: 600;
    font-size: 16px;
    padding: 7px 13px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 15px;
  }
}
.errorParagraph {
  color: rgb(255, 59, 59);
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.forget-pass-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.password-sent-with-success {
  color: #57b5c9;
  display: flex;
  align-items: center;
  flex-direction: column;
  .error-code {
    color: red;
    font-size: 13px;
  }
  #success {
    font-weight: 600;
    font-size: 17px;
    width: 90%;
    text-align: center;
  }
}

.code-verify-inputs {
  @media (max-width: 680px) {
    width: 288px !important;
  }
  .styles_react-code-input__CRulA > input {
    @media (max-width: 680px) {
      width: 48px !important;
      height: 48px !important;
    }
  }
}
.code-error {
  .styles_react-code-input__CRulA > input {
    border-color: red;
    &:last-child {
      border-right-color: red;
    }
    &:focus {
      border-color: red;
    }
  }
}
.code-activated {
  .styles_react-code-input__CRulA > input {
    border-color: #57b5c9;
    &:last-child {
      border-right-color: #57b5c9;
    }
    &:focus {
      border-color: #57b5c9;
    }
  }
}
.activated-code {
  color: #57b5c9;
  font-size: 13px;
}
.resend-button {
  margin-top: 20px;
  text-align: center;
  .resend-btn {
    padding: 4px 9px;
    outline: 0;
    color: white;
    background-color: #57b5c9;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      color: #57b5c9;
      background-color: white;
      border-color: #57b5c9;
    }
  }
  .code-sent-again {
    color: #0e8a00;
    font-size: 13px;
    margin-top: 7px;
  }
}
.reset-form {
  .new-password-confirm,
  .create-new-password {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    label {
      color: #2ba7df;
      font-weight: 500;
    }
    input {
      margin-top: 7px;
      width: 280px;
      height: 35px;
    }
  }
  button.mt0 {
    margin-top: 0;
  }
}
.forget-pass-form {
  p.errorParagraph {
    color: red;
  }

}
