.SettingsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 35px;
  padding-right: 20px;
  padding-top: 30px;
  .right-side {
    flex: 1;
  }
  .left-side {
    flex: 1;
  }
}
