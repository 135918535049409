.Settings-container{
max-width: 1500px;
margin:auto;
display: flex;
flex-direction: column;
gap: 25px;
min-height: 100vh;
padding: 0px 20px;
.user-settings-header{
color: #21BDCA;
margin-top: 35px;
}
}
.settings-large-container{
    background-color: #e5fcf3;
}