.header-container:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 700px;
  background-color: #e5fcf3;
  z-index: -1;
  transform: skewY(-4deg);
  transform-origin: top left;
  @media (max-width: 660px) {
    min-height: 600px;
  }
}
.HomePage-huge-Container {
  max-width: 1600px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0px auto;
  @media (max-width: 450px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: 370px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.landing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

}
.company-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.company-logo .company-name {
  font-weight: 700;
  font-size: 20px;
  color: #2ba7df;
  @media (max-width: 660px) {
    font-size: 17px;
  }
}
.company-logo .logo img {
  width: 50px;
  height: 50px;
  @media (max-width: 660px) {
    width: 35px;
    height: 35px;
  }
}
.navbar_list-links {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 90px;
}
.navbar_list-links {
  & > li {
    list-style: none;
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
    color: #21bdca;
    @media (max-width: 1200px) {
      font-size: 17px;
    }
    & > a {
      text-decoration: none;
      color: #21bdca;
      &:hover {
        color: black;
      }
    }
  }
}
.links-signin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.signin-register {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 660px) {
    gap: 10px;
  }
}
.signin-register .register {
  border: 1px solid transparent;
  background-color: #2ba7df;
  color: white;
  padding: 13px 20px;
  border-radius: 10px;
  font-size: 19px;
  font-weight: 500;
  margin-left: 10px;
  transition: 0.3s;
  cursor: pointer;
  @media (max-width: 660px) {
    display: none;
  }
}
.signin-register .register:hover {
  border: 1px solid #2ba7df;
  background-color: white;
  color: #2ba7df;
}
.signin-register a {
  text-decoration: none;
}
.small-media {
  display: none;

  @media (max-width: 660px) {
    display: flex;
  }
}
.signin {
  color: #21bdca;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 2px solid transparent;
  padding: 10px 14px;
  height: 48px;
  @media (max-width: 660px) {
    display: none;
  }
  &:hover {
    border: 2px solid #21bdca;
    border-radius: 10px;
    cursor: pointer;
  }
}
.landing-section {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-description .main-description_recruiting {
  color: #2ba7df;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 20px;
}
.main-description_head {
  line-height: 1.2em;
  font-size: 2.6em;
  font-weight: 600;
  margin-bottom: 16px;
  word-spacing: 2px;
  @media (max-width: 485px) {
    font-size: 2.2em;
  }
  @media (max-width: 370px) {
    font-size: 2em;
  }
  @media (max-width: 335px) {
    font-size: 1.8em;
  }
}
#dream {
  color: #21bdca;
}
#easy {
  color: #2ba7df;
}
.main-description .main-description_desc {
  line-height: 38px;
  color: #737791;
  max-width: 650px;
  font-size: 17px;
  font-weight: 400;
  @media (max-width: 660px) {
    line-height: 25px;
  }
  @media (max-width: 335px) {
    font-size: 14px;
  }
}
.join-now {
  border: 1px solid transparent;
  background-color: #060919;
  color: white;
  padding: 13px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  margin-top: 25px;
  cursor: pointer;
  @media (max-width: 335px) {
    padding: 9px 10px;
    font-size: 14px;

  }

}
.join-now:hover {
  color: #060919;
  border-color: #060919;
  background-color: white;
}
.man-image-section {
  z-index: 2;
  position: relative;
}
.man-image-section:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -51%);
  z-index: -1;
  width: 90%;
  height: 90%;
  background-color: #34d399;
  border-radius: 100%;
}
.image-section {
  position: relative;
  @media (max-width: 660px) {
    display: none;
  }
}
.how-many-employee {
  background-color: white;
  border-radius: 15px;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 14px 7px;
  gap: 5px;
  box-shadow: 2px 2px 13px 7px rgba(202, 202, 202, 0.49);
  position: absolute;
  top: 60px;
  z-index: 5;
}
.how-many-employee .employee-number {
  color: #f29900;
  font-weight: 800;
  font-size: 20px;
}
.how-many-employee .employee {
  font-size: 14px;
  color: #737791;
}
.ad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 250px;
  gap: 5px;
  background-color: white;
  border-radius: 10px;
  padding: 7px;
  box-shadow: 2px 2px 13px 7px rgba(202, 202, 202, 0.49);
  position: absolute;
  z-index: 5;
}
.ad .ad-img {
  padding: 7px;
  border-radius: 5px;
  display: flex;
}
.ad .ad-img img {
  width: 30px;
  height: 30px;
}
.web-dev-ad-img {
  background-color: rgba(255, 192, 203, 0.575);
}
.designer-ad-img {
  background-color: #c6e6f5ab;
}
.ad-job {
  font-weight: 600;
  font-size: 14px;
}
.we_are-searching {
  font-family: 500;
  font-size: 12px;
  color: #737791;
}
.ad-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.apply-btn {
  padding: 2px 5px;
  border-radius: 7px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
}
.design-btn {
  color: #2ba7df;
  background-color: #c6e6f5ab;
  border-color: #2ba7df;
}
.dev-btn {
  color: rgb(250, 114, 136);
  background-color: rgba(255, 192, 203, 0.575);
  border-color: pink;
}
.web-dev-ad {
  top: 300px;
  left: -80px;
}
.designer-ad {
  top: 200px;
  right: -40px;
}
.footer-home {
  width: 100%;
  height: fit-content;
  background-color: #e5fcf3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  .footer-map {
    width: 100%;
    height: 300px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .footer-infos {
    width: 100%;
    // background-color: #21bdca;
  }
}
@media (max-width: 1200px) {
  .landing-section {
    flex-direction: column-reverse;
    gap: 40px;
  }
  .landing-section .main-description {
    text-align: center;
  }
}

@media (max-width: 969px) {
  .navbar {
    display: none;
  }
  .signin-register .register {
    padding: 10px 15px;
    font-size: 17px;
  }
  .signin {
    font-size: 17px;
  }
}

@media (max-width: 900px) {
  .navbar {
    display: none;
  }
}
.main-container {
  margin-top: 50px;
}
.main-Container {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*//////// START CONTACT ///////// */
/* Start Contact Section Styling */
.contact-section-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}
.contacts-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 45px;
  height: fit-content;
  text-align: center;
  color: black;
  padding-top: 55px;
  @media (max-width: 763px) {
    flex-direction: column;
    gap: 10px;
  }

}
.contact-section-title {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: black;
  margin-top: 34px;
}
.contact-phone-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  flex: 1;
}
.contact-phone-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 25px;
  @media (max-width: 763px) {
    padding-bottom: 10px;
  }
}
.contact-phone-number {
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
  align-items: center;
  @media (max-width: 763px) {
    margin-bottom: 0px;
  }
}
.phone-icon {
  display: flex;
  filter: invert(56%) sepia(83%) saturate(466%) hue-rotate(136deg)
    brightness(97%) contrast(79%);
  transform: scale(0.7);
}
.phone-num {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
}
.contact-social-media-wrapper {
  display: flex;
  flex-direction: column;
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  align-items: center;
}
.contact-social-media-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 26px;
  @media (max-width: 763px) {
    margin-bottom: 0px;
  }
}
.contact-social-media {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  @media (max-width: 763px) {
    margin-bottom: 0px;
  }
}
.social-media-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
}
.address-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  align-items: center;
}
.contact-address-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 16px;
  @media (max-width: 763px) {
    padding-bottom: 6px;
  }
}
.address {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  @media (max-width: 763px) {
    margin-bottom: 5px;
  }
}
.address-text {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-right: 3px;
  margin-left: 2px;
  @media (max-width: 450px) {
    font-size: 15px;
  }
}
.local-icon-wrapper {
  display: flex;
}
.local-icon {
  display: flex;
  transform: scale(0.6);
  filter: invert(56%) sepia(83%) saturate(466%) hue-rotate(136deg)
    brightness(97%) contrast(79%);
}
.sub-address {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-left: -21px;
}
.fb-icon {
  margin-left: 0px;
  display: flex;
  filter: invert(56%) sepia(83%) saturate(466%) hue-rotate(136deg)
    brightness(97%) contrast(79%);
  transform: scale(0.7);
}
.yt-icon {
  margin-left: 10px;
  filter: invert(56%) sepia(83%) saturate(466%) hue-rotate(136deg)
    brightness(97%) contrast(79%);
  transform: scale(0.7);
}
.insta-icon {
  filter: invert(56%) sepia(83%) saturate(466%) hue-rotate(136deg)
    brightness(97%) contrast(79%);
  transform: scale(0.7);
}
/* End Contact Section Styling */
