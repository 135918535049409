.register-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5fcf3;
}
.register-section {
  display: flex;
  justify-content: center;
}
.register-aside {
  width: 250px;
  padding: 0 20px 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 70px;
  background-image: url("../../assets/images/background.svg"),
    linear-gradient(to top, #34d399ad 0%, #2ba7dfb0 100%);
}

#register {
  color: #2ba7df;
  margin-bottom: 25px;
  font-size: 30px;
}
.close-to-join {
  color: white;
  font-weight: 500;
  font-size: 18px;
  max-width: 300px;
  margin-bottom: 40px;
  text-align: center;
}
.signin-form {
  background-color: white;
  padding: 70px;
}
.register-desc {
  font-size: 14px;
  font-weight: 400;
  color: #737791;
  max-width: 450px;
  margin-bottom: 25px;
}
.register-desc2 {
  font-size: 18px;
  font-weight: 600;
  color: #2ba7df;
  margin-bottom: 15px;
}
.levels-section-select,
.first-lastname-inputs,
.phonenumber-email-inputs,
.password-confirm-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 20px;
}
.gender-select {
  margin-bottom: 16px;
}
.gender-select > div,
.levels-section-select > div,
.first-lastname-inputs > div,
.phonenumber-email-inputs > div,
.password-confirm-inputs > div,
.select-your-class > div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
}
.levels-section-select > div > select,
.first-lastname-inputs > div > input,
.phonenumber-email-inputs > div > input,
.password-confirm-inputs > div > input,
.input-licence-diploma > input {
  width: 280px;
  height: 35px;
  padding-left: 10px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 5px;
}
.gender-select > div > select {
  height: 35px;
  padding-left: 10px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 5px;
}

.gender-select > div > label,
.levels-section-select > div > label,
.first-lastname-inputs > div > label,
.phonenumber-email-inputs > div > label,
.password-confirm-inputs > div > label {
  font-weight: 500;
  font-size: 16px;
  color: #2ba7df;
}
.accept-terms {
  margin-bottom: 15px;
}
.accept-terms span {
  color: #2ba7df;
}
.submit-register {
  background-color: #2ba7df;
  color: white;
  border: 2px solid transparent;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    background: white;
    color: #2ba7df;
    border-color: #2ba7df;
  }
}
a.login-link {
  color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
  padding: 6px 12px;
  border-color: #fff;
  font-size: 15px;
  border-radius: 5px;
  align-items: center;
}
.select-your-class {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 24px;
  gap: 20px;
}
.select-your-class select {
  width: 280px;
  height: 37px;
  color: #2ba7df;
  padding-left: 10px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 5px;
}
.accept-terms {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1050px) {
  .register-aside {
    display: none;
  }
}
@media (max-width: 760px) {
  .register-section {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .signin-form {
    padding: 50px;
  }
  .levels-section-select,
  .first-lastname-inputs,
  .phonenumber-email-inputs,
  .password-confirm-inputs {
    display: block;
    margin-bottom: 10px;
  }
  .select-your-class {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .levels-section-select > div,
  .first-lastname-inputs > div,
  .phonenumber-email-inputs > div,
  .password-confirm-inputs > div,
  .select-your-class > div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 4px;
    margin-bottom: 10px;
  }
  .levels-section-select> div> select,
  .first-lastname-inputs > div > input,
  .phonenumber-email-inputs > div > input,
  .password-confirm-inputs > div > input {
    width: 100%;
    height: 35px;
    padding-left: 10px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 5px;
  }
}
@media (max-width: 450px) {
  .signin-form {
    margin: 10px 10px;
    padding: 30px;
  }
  .register-desc2 {
    font-size: 14px;
  }
  .register-desc {
    font-size: 13px;
  }
  #register {
    font-size: 25px;
  }
  .first-lastname-inputs > div > input,
  .phonenumber-email-inputs > div > input,
  .password-confirm-inputs > div > input {
    height: 25px;
  }
  .first-lastname-inputs > div > label,
  .phonenumber-email-inputs > div > label,
  .password-confirm-inputs > div > label,
  .select-your-class > div > label {
    font-size: 14px;
  }
  .accept-terms label {
    font-size: 11px;
  }
  .submit-register {
    padding: 5px 10px;
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .register-desc {
    font-size: 11.5px;
  }
  #register {
    font-size: 25px;
  }

  .first-lastname-inputs > div > input,
  .phonenumber-email-inputs > div > input,
  .password-confirm-inputs > div > input {
    height: 25px;
  }
  .first-lastname-inputs > div > label,
  .phonenumber-email-inputs > div > label,
  .password-confirm-inputs > div > label,
  .select-your-class > div > label {
    font-size: 14px;
  }
  .accept-terms label {
    font-size: 11px;
  }
  .submit-register {
    padding: 5px 10px;
    font-size: 14px;
  }
}
.register-error {
  color: red;
  font-size: 11px;
  margin-bottom: 5px;
}
