.form-body {
  background-color: #e1e8eb;
  width: 100%;
  .header {
    display: none;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%) !important;
    background-color: #fff;
    .header_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 53px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2px;
      width: 100%;
      padding: 5px 40px;
      box-shadow: 0px 1px 2px rgb(0 0 0 / 20%) !important;
      gap: 20px;
      .logo-header-btn-wrapper {
        display: flex;
        gap: 50px;
        align-items: center;
        cursor: pointer;
        // Start Logo Styling //
        .logo {
          display: flex;
          height: fit-content;
          gap: 7px;
          .logo-icon {
            width: 40px;
            height: 40px;
          }
          .logo-text {
            color: #2ba7df;
            height: 45px;
            font-size: 20px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .company-page-btn1 {
          color: #34a9f6;
          border: 2px solid #34a9f6;
          border-radius: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
        }
        // End Logo Styling //
      }

      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        justify-content: space-between;
        padding: 0px 10px;
        .signUp-btn {
          height: 30px;
          padding: 0px 10px;
          background-color: #2ba7df;
          border: 2px solid transparent;
          color: white;
          font-size: 16px;
          border-radius: 3px;
          font-weight: bold;
          cursor: pointer;
          &:hover {
            background-color: white;
            border: 2px solid #2ba7df;
            color: #2ba7df;
          }
        }
        .login-btn {
          height: 30px;
          padding: 0px 10px;
          background-color: white;
          border: 2px solid #2ba7df;
          color: #2ba7df;
          font-size: 16px;
          border-radius: 3px;
          font-weight: bold;
          cursor: pointer;
          &:hover {
            background-color: #2ba7df;
            border: 2px solid transparent;
            color: white;
          }
        }
      }
    }
  }

  .job-title-company-name-wrapper {
    display: flex;
    width: 99%;
    margin: auto;
    padding: 23px 14.5% 13px 14.5%;
    justify-content: space-between;
    margin-bottom: 11px;
    box-shadow: rgb(17 17 26 / 10%) 0px 1px 0px;
    .job-title,
    .company-name {
      display: flex;
      flex-direction: row;
      gap: 5px;
      & > label {
        font-weight: bold;
        color: #7d838a;
        font-size: 17px;
        @media (max-width: 560px) {
          display: none;
        }
      }
      & > span {
        font-weight: bold;
        font-size: 17px;
      }
    }
  }

  .container {
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 40px;
    .form-title-wrapper {
      display: flex;
      gap: 15px;
      align-items: flex-end;
      justify-content: center;
      color: #2ba7df;
      .form-title {
        font-size: 24px;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    .form-description {
      font-size: 15px;
      color: #7d838a;
      width: fit-content;
      margin: auto;
      margin-top: 20px;
      @media (max-width: 560px) {
        font-size: 14px;
        text-align: center;
      }
    }
    .form-container {
      padding: 30px 8%;
      form {
        margin-top: 30px;
        width: 95%;
        margin: auto;
        margin-top: 30px;
        .form-title,
        .resume-title {
          font-size: 24px;
          text-decoration: underline;
          font-weight: bold;
          color: #2ba7df;
          @media (max-width: 560px) {
            font-size: 20px;
          }
        }
        .general-infos {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          gap: 10px;
          .full-name {
            display: flex;
            width: 100%;
            justify-content: space-between;
            @media (max-width: 820px) {
              flex-direction: column;
            }
          }
          .email-number {
            display: flex;
            width: 100%;
            justify-content: space-between;
            @media (max-width: 820px) {
              flex-direction: column;
            }
            .phone-number-wrapper {
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: left;
              align-items: center;
              select {
                height: 38px;
                margin-top: 3px;
                // color: rgba(0, 0, 0, 0.87);
                border: 1px solid #c4c4c4;
                border-radius: 3px;
                padding: 3px;
                cursor: pointer;
                color: #65686b;
              }
            }
          }
        }
        .social-media-infos {
          display: flex;
          justify-content: space-between;
          margin: 30px 0px;
          @media (max-width: 820px) {
            flex-direction: column;
          }
        }
        .bachelor-infos-wrapper {
          width: 100%;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          .section-level-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            @media (max-width: 820px) {
              flex-direction: column;
            }
          }
          .small-form-header {
            color: #2ba7df;
            text-decoration: underline;
            font-weight: bold;
            margin: 10px 0;
          }
          .marks-inputs-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 5px;
            @media (max-width: 750px) {
              grid-template-columns: 1fr 1fr;
              gap: 20px;
            }
          }
        }
        .submit-btn-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 35px;
          .submit-form-btn {
            padding: 10px;
            background-color: #2ba7df;
            border: 1px solid white;
            color: white;
            font-size: 18px;
            border-radius: 5px;
            cursor: pointer;
            &.btn-submitting {
              background-color: #7d838a;
              cursor: not-allowed;
            }
            &.btn-submitting:hover {
              background-color: #7d838a;
              cursor: not-allowed;
              border: 1px solid #7d838a;
              color: white;
            }
            @media (max-width: 560px) {
              padding: 7px;
              font-size: 16px;
              font-weight: 500;
            }
            &:hover {
              background-color: white;
              border: 1px solid #2ba7df;
              color: #2ba7df;
            }
          }
        }
      }
    }
  }
  .upload-area-wrapper {
    width: 100%;
    background-color: #e1e8eb54;
    border: 2px dotted #2ba7df;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    margin: 20px 0;
    transition: 0.3s;
    &:hover {
      border-style: solid;
    }
    &.pp-error,
    &.transcript-error,
    &.ml-error,
    &.resume-error {
      background-color: rgb(255 241 241);
      border: 2px dotted #ff6f6f;
      .upload-input input::file-selector-button{
        border-color: #ff6f6f !important;
        color: #ff6f6f !important;
      }
    }
    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    .upload-button {
      width: 125px;
      padding: 5px;
      background-color: #2ba7df;
      border: 1px solid white;
      color: white;
      font-size: 16px;
      border-radius: 5px;
      height: 30px;
      cursor: pointer;
      &:hover {
        background-color: white;
        border: 1px solid #2ba7df;
        color: #2ba7df;
      }
    }
    .left {
      display: flex;
      flex: 0.65;
      flex-direction: column;
      gap: 10px;
      @media (max-width: 640px) {
        align-items: center;
      }
      .title {
        font-weight: bold;
        font-size: 17px;
      }
      .description {
        font-size: 13px;
        color: #7d838a;
      }
      .upload-input {
        @media (max-width: 640px) {
          margin-left: 60px;
        }
        input::file-selector-button {
          font-weight: bold;
          color: #2ba7df;
          padding: 0.5em;
          border: thin solid #2ba7df;
          border-radius: 3px;
          background-color: white;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
    .preview-avatar-upload {
      width: 120px;
      height: 120px;
      display: flex;
      border: 0.5px solid #7d838a;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .preview-file-upload {
      height: 30px;
      border: 1px solid #2ba7df;
      padding: 5px 10px;
      width: fit-content;
      font-size: 15px;
      color: #7d838a;
      border-radius: 5px;
    }
    .right-files {
      display: flex;
      flex-direction: row;
      gap: 10px;
      height: fit-content;
      align-self: flex-end;
    }
  }
  .footer {
    height: 50px;
    width: 100%;
    background-color: #e1e8eb;
  }
}
.error-marks {
  border: 1px solid red;
}

.area-red {
  border: 2px dotted red;
}

@media (max-width: 1300px) {
  .form-body {
    .container {
      width: 85%;
    }
  }
}
@media (max-width: 1050px) {
  .form-body {
    .container {
      width: 92%;
      .form-container {
        padding: 30px 20px;
      }
    }
  }
}
@media (max-width: 900px) {
  .form-body {
    .container {
      padding: 20px;
      .form-container {
        padding: 30px 10px;
      }
    }
  }
}
@media (max-width: 560px) {
  .form-body {
    .container {
      width: 97%;
      padding: 15px;
      .form-container {
        padding: 10px 10px;
      }
    }
  }
}
.xs-width {
  @media (max-width: 820px) {
    width: 100% !important;
  }
}
