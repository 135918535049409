@use "../abstracts/colors" as *;
@use "../abstracts/index" as *;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500&family=Roboto:wght@300;400;500;700&family=Tajawal:wght@400;500;700&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html{
  scroll-behavior: smooth;
}
body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", "SST Roman", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}
#root {
  // height: fit-content;
}
#portal {
}
