.job-position-wrapper {
  background-color: #fff;
  box-shadow: 3px 3px 5px 3px rgb(50 50 105 / 15%), 0 1px 1px 0 rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  cursor: pointer;
  .job-position__description {
    height: 0;
    overflow: hidden;
    transition: 0.2s;
    margin-top: 10px;
    p {
      box-sizing: content-box;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      text-transform: capitalize;
      text-indent: 0px;
      transition: 0.3s;
      color: grey;
    }
  }
  @media (max-width: 660px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  &:hover {
    background-color: #e1e8eb;
    .job-position__description p {
      text-indent: 15px;
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title {
      color: #2ba7df;
      font-weight: 500;
      font-size: 18px;
    }
    .other-infos {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      @media (max-width: 840px) {
        justify-content: center;
        gap: 10px;
      }
      .company,
      .location {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 3px;
        color: #7d838a;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .take-action {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .expiry-date {
      color: #7d838a;
      font-weight: bold;
      font-size: 15px;
      display: flex;
      flex-direction: row;
      gap: 3px;
    }
    .disabled {
      padding: 5px 10px;
      background-color: grey;
      border: 2px solid transparent;
      color: white;
      font-size: 16px;
      border-radius: 3px;
      font-weight: 500;
      cursor: not-allowed;
    }
    .apply-btn {
      height: 30px;
      padding: 5px 10px;
      background-color: #2ba7df;
      border: 2px solid transparent;
      color: white;
      font-size: 16px;
      border-radius: 3px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        border: 2px solid #2ba7df;
        color: #2ba7df;
      }
    }
    .applied {
      background-color: #7d838a;
      cursor: not-allowed;
      &:hover {
        background-color: #7d838a;
        border: 2px solid #7d838a;
        color: white;
      }
    }
  }
}
.edit-my-application {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 7px;
  background-color: white;
  border: 1px solid #2ba7df;
  font-size: 15px;
  border-radius: 5px;
  font-weight: 600;
  gap: 7px;
  color: #2ba7df;
  align-self: self-end;
  cursor: pointer;
  position: relative;
}
.edit-image-wrapper {
  width: 25px;
  height: 25px;
  display: flex;
  padding: 1px;
}
.button-effect-a:focus {
  outline: none;
  color: #3c8ddc;
  border-color: #3c8ddc;
}
