.finish-application-body {
  background-color: #e1e8eb;
  width: 100%;
  height: 100vh;
  padding: 40px 0px;
  .finish-application-wrapper {
    width: 80%;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .finish-application-picture {
      width: 400px;
      height: 400px;
      object-fit: contain;
    }
    .finish-application-title {
      font-size: 27px;
      font-weight: bold;
      color: #151640;
    }
    .finish-application-description {
      font-size: 16px;
      color: dimgrey;
    }
    .finish-application-button {
      height: 35px;
      padding: 5px 20px;
      border-radius: 25px;
      background-color: #151640;
      color: white;
      margin: auto;
      align-items: center;
      border: 2px solid #151640;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      margin-top: 40px;

      &:hover {
        color: #151640;
        background-color: white;
      }
    }
  }
}
