.company-page-body {
  background-color: #e1e8eb;
  width: 100%;
  max-width: 1905px;
  margin: auto;
}
.company-page-container {
  width: 70%;
  margin: auto;
  margin-bottom: 10px;
  height: fit-content;
  background-color: #fff;
  position: relative;
  padding-bottom: 100px;
  @media (max-width: 840px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 90%;
  }
  .cover {
    width: 100%;
    height: 360px;
    background: url(../../assets/icons/cover.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(50%);
    margin-top: -1px;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .comany-name-logo-wrapper {
    width: 80%;
    margin: auto;
    background-color: transparent;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: flex-end;
    @media (max-width: 600px) {
      display: none;
    }
    .company-logo-wrapper {
      display: flex;
      flex-direction: row;
      height: 150px;
      width: 150px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      font-size: 29px;
      font-weight: 800;
      margin-bottom: 13px;
      color: #2ba7df;
    }
  }
  .jobs-section {
    margin-bottom: 50px;
    width: 85%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 600px) {
      padding-top: 40px;
    }
    .no-offers-yet{
      color: grey;
      font-weight: 600;
      font-size: 17px;
    }
    .open-jobs-title {
      font-size: 28px;
      font-weight: 700;
      height: 50px;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    }
  }
  .life-at-company-title {
    width: 85%;
    margin: auto;
    font-size: 27px;
    font-weight: 700;
    height: 50px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    margin-top: 65px;
  }

  .life-at-company {
    padding: 20px 10px;
    width: 85%;
    margin: auto;
    display: flex;
    gap: 20px;
    @media (max-width: 1075px) {
      flex-direction: column;
    }
    .left {
      flex: 0.5;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 0.5;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      gap: 30px;
      @media (max-width: 1075px) {
        justify-content: space-between;
      }
      & > img {
        width: 45%;
        height: 45%;
      }
    }
  }
}
.door-to-dashboard {
  display: none;
  border: 2px solid #34a9f6;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  margin-right: 25px;
  .door {
    width: 22px;
  }
  @media (max-width: 600px) {
    display: block;
    display: flex;
  }
}