.inbox-large-container {
  background-color: #e5fcf3;
  min-height: 100vh;
  .inbox-container {
    max-width: 1500px;
    padding: 0 20px ;
    padding-bottom: 50px;
    margin: auto;
  }
}
.inbox-head {
  h1 {
    color: #21bdca;
    padding-top: 35px;
  }
}