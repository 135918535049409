.NotFoundComponent {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5fcf3;
  color: #34a9f6;
  .not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .error-404 {
      font-weight: 700;
      font-size: 120px;
      padding-right: 20px;
      border-right: 2px solid rgb(184, 184, 184);
    }
    .oops-desc {
      display: flex;
      font-weight: 600;
      font-size: 20px;
      max-width: 350px;
      flex-direction: column;
      color: #34a9f6;
    }
    .go-back-home {
        padding: 10px 20px;
        outline: 0;
        margin-top: 10px;
        background: transparent;
        color: #34a9f6;
        border: 1px solid;
        border-radius: 5px;
        transition: .3s all;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        &:hover {
            background: #34a9f6;
            color: #fff;
        }
    } 
  }
}
