.updateMe-section {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 20%) !important;
  .updateMe-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .update-me-header {
    gap: 5px;
    display: flex;
    align-items: center;
    color: #737791;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
    p {
      text-decoration: underline;
    }
    img {
      filter: invert(59%) sepia(66%) saturate(517%) hue-rotate(136deg)
        brightness(93%) contrast(89%);
    }
  }
  .updateMe-fields {
    .update-section-level {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
    }
    .updateMe-usersection,
    .updateMe-level {
      display: flex;
      align-items: left;
      flex-direction: column;
      flex: 1;
      label {
        color: #737791;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 5px;
      }
    }
    .updateMe-selects {
      width: 100%;
      outline: none;
      border: 1px solid #737791;
      height: 35px;
      border-radius: 5px;
      margin: 8px 0;
      padding-left: 10px;
    }
    .updateMe-inputs {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      label {
        color: #737791;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 5px;
      }
      input {
        width: 100%;
        outline: none;
        border: 1px solid #737791;
        height: 35px;
        border-radius: 5px;
        margin: 8px 0;
        padding-left: 10px;
      }
    }
  }
  .update-btn {
    background-color: #21bdca;
    color: white;
    border: none;
    font-weight: 600;
    font-size: 16px;
    padding: 7px 15px;
    border-radius: 10px;
    align-self: flex-end;
  }
  .error {
    color: rgb(255, 59, 59);
    font-size: 13px;
    font-weight: 500;
  }
  .success {
    color: #21bdca;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
  }
}
